import styles from './Flags.module.scss';
import classNames from 'classnames';

import img_af from './img/af.webp';
import img_ax from './img/ax.webp';
import img_al from './img/al.webp';
import img_dz from './img/dz.webp';
import img_as from './img/as.webp';
import img_ad from './img/ad.webp';
import img_ao from './img/ao.webp';
import img_ai from './img/ai.webp';
import img_aq from './img/aq.webp';
import img_ag from './img/ag.webp';
import img_ar from './img/ar.webp';
import img_am from './img/am.webp';
import img_aw from './img/aw.webp';
import img_au from './img/au.webp';
import img_at from './img/at.webp';
import img_az from './img/az.webp';
import img_bs from './img/bs.webp';
import img_bh from './img/bh.webp';
import img_bd from './img/bd.webp';
import img_bb from './img/bb.webp';
import img_by from './img/by.webp';
import img_be from './img/be.webp';
import img_bz from './img/bz.webp';
import img_bj from './img/bj.webp';
import img_bm from './img/bm.webp';
import img_bt from './img/bt.webp';
import img_bo from './img/bo.webp';
import img_ba from './img/ba.webp';
import img_bw from './img/bw.webp';
import img_bv from './img/bv.webp';
import img_br from './img/br.webp';
import img_io from './img/io.webp';
import img_bn from './img/bn.webp';
import img_bg from './img/bg.webp';
import img_bf from './img/bf.webp';
import img_bi from './img/bi.webp';
import img_kh from './img/kh.webp';
import img_cm from './img/cm.webp';
import img_ca from './img/ca.webp';
import img_cv from './img/cv.webp';
import img_bq from './img/bq.webp';
import img_ky from './img/ky.webp';
import img_cf from './img/cf.webp';
import img_td from './img/td.webp';
import img_cl from './img/cl.webp';
import img_cn from './img/cn.webp';
import img_cx from './img/cx.webp';
import img_cc from './img/cc.webp';
import img_co from './img/co.webp';
import img_km from './img/km.webp';
import img_cg from './img/cg.webp';
import img_cd from './img/cd.webp';
import img_ck from './img/ck.webp';
import img_cr from './img/cr.webp';
import img_ci from './img/ci.webp';
import img_hr from './img/hr.webp';
import img_cu from './img/cu.webp';
import img_cw from './img/cw.webp';
import img_cy from './img/cy.webp';
import img_cz from './img/cz.webp';
import img_dk from './img/dk.webp';
import img_dj from './img/dj.webp';
import img_dm from './img/dm.webp';
import img_do from './img/do.webp';
import img_ec from './img/ec.webp';
import img_eg from './img/eg.webp';
import img_sv from './img/sv.webp';
import img_gb_eng from './img/gb-eng.webp';
import img_gq from './img/gq.webp';
import img_er from './img/er.webp';
import img_ee from './img/ee.webp';
import img_sz from './img/sz.webp';
import img_et from './img/et.webp';
import img_fk from './img/fk.webp';
import img_fo from './img/fo.webp';
import img_fj from './img/fj.webp';
import img_fi from './img/fi.webp';
import img_fr from './img/fr.webp';
import img_gf from './img/gf.webp';
import img_pf from './img/pf.webp';
import img_tf from './img/tf.webp';
import img_ga from './img/ga.webp';
import img_gm from './img/gm.webp';
import img_ge from './img/ge.webp';
import img_de from './img/de.webp';
import img_gh from './img/gh.webp';
import img_gi from './img/gi.webp';
import img_gr from './img/gr.webp';
import img_gl from './img/gl.webp';
import img_gd from './img/gd.webp';
import img_gp from './img/gp.webp';
import img_gu from './img/gu.webp';
import img_gt from './img/gt.webp';
import img_gg from './img/gg.webp';
import img_gn from './img/gn.webp';
import img_gw from './img/gw.webp';
import img_gy from './img/gy.webp';
import img_ht from './img/ht.webp';
import img_hm from './img/hm.webp';
import img_hn from './img/hn.webp';
import img_hk from './img/hk.webp';
import img_hu from './img/hu.webp';
import img_is from './img/is.webp';
import img_in from './img/in.webp';
import img_id from './img/id.webp';
import img_ir from './img/ir.webp';
import img_iq from './img/iq.webp';
import img_ie from './img/ie.webp';
import img_im from './img/im.webp';
import img_il from './img/il.webp';
import img_it from './img/it.webp';
import img_jm from './img/jm.webp';
import img_jp from './img/jp.webp';
import img_je from './img/je.webp';
import img_jo from './img/jo.webp';
import img_kz from './img/kz.webp';
import img_ke from './img/ke.webp';
import img_ki from './img/ki.webp';
import img_kp from './img/kp.webp';
import img_kr from './img/kr.webp';
import img_xk from './img/xk.webp';
import img_kw from './img/kw.webp';
import img_kg from './img/kg.webp';
import img_la from './img/la.webp';
import img_lv from './img/lv.webp';
import img_lb from './img/lb.webp';
import img_ls from './img/ls.webp';
import img_lr from './img/lr.webp';
import img_ly from './img/ly.webp';
import img_li from './img/li.webp';
import img_lt from './img/lt.webp';
import img_lu from './img/lu.webp';
import img_mo from './img/mo.webp';
import img_mg from './img/mg.webp';
import img_mw from './img/mw.webp';
import img_my from './img/my.webp';
import img_mv from './img/mv.webp';
import img_ml from './img/ml.webp';
import img_mt from './img/mt.webp';
import img_mh from './img/mh.webp';
import img_mq from './img/mq.webp';
import img_mr from './img/mr.webp';
import img_mu from './img/mu.webp';
import img_yt from './img/yt.webp';
import img_mx from './img/mx.webp';
import img_fm from './img/fm.webp';
import img_md from './img/md.webp';
import img_mc from './img/mc.webp';
import img_mn from './img/mn.webp';
import img_me from './img/me.webp';
import img_ms from './img/ms.webp';
import img_ma from './img/ma.webp';
import img_mz from './img/mz.webp';
import img_mm from './img/mm.webp';
import img_na from './img/na.webp';
import img_nr from './img/nr.webp';
import img_np from './img/np.webp';
import img_nl from './img/nl.webp';
import img_nc from './img/nc.webp';
import img_nz from './img/nz.webp';
import img_ni from './img/ni.webp';
import img_ne from './img/ne.webp';
import img_ng from './img/ng.webp';
import img_nu from './img/nu.webp';
import img_nf from './img/nf.webp';
import img_mk from './img/mk.webp';
import img_gb_nir from './img/gb-nir.webp';
import img_mp from './img/mp.webp';
import img_no from './img/no.webp';
import img_om from './img/om.webp';
import img_pk from './img/pk.webp';
import img_pw from './img/pw.webp';
import img_ps from './img/ps.webp';
import img_pa from './img/pa.webp';
import img_pg from './img/pg.webp';
import img_py from './img/py.webp';
import img_pe from './img/pe.webp';
import img_ph from './img/ph.webp';
import img_pn from './img/pn.webp';
import img_pl from './img/pl.webp';
import img_pt from './img/pt.webp';
import img_pr from './img/pr.webp';
import img_qa from './img/qa.webp';
import img_re from './img/re.webp';
import img_ro from './img/ro.webp';
import img_ru from './img/ru.webp';
import img_rw from './img/rw.webp';
import img_bl from './img/bl.webp';
import img_sh from './img/sh.webp';
import img_kn from './img/kn.webp';
import img_lc from './img/lc.webp';
import img_mf from './img/mf.webp';
import img_pm from './img/pm.webp';
import img_vc from './img/vc.webp';
import img_ws from './img/ws.webp';
import img_sm from './img/sm.webp';
import img_st from './img/st.webp';
import img_sa from './img/sa.webp';
import img_gb_sct from './img/gb-sct.webp';
import img_sn from './img/sn.webp';
import img_rs from './img/rs.webp';
import img_sc from './img/sc.webp';
import img_sl from './img/sl.webp';
import img_sg from './img/sg.webp';
import img_sx from './img/sx.webp';
import img_sk from './img/sk.webp';
import img_si from './img/si.webp';
import img_sb from './img/sb.webp';
import img_so from './img/so.webp';
import img_za from './img/za.webp';
import img_gs from './img/gs.webp';
import img_ss from './img/ss.webp';
import img_es from './img/es.webp';
import img_lk from './img/lk.webp';
import img_sd from './img/sd.webp';
import img_sr from './img/sr.webp';
import img_sj from './img/sj.webp';
import img_se from './img/se.webp';
import img_ch from './img/ch.webp';
import img_sy from './img/sy.webp';
import img_tw from './img/tw.webp';
import img_tj from './img/tj.webp';
import img_tz from './img/tz.webp';
import img_th from './img/th.webp';
import img_tl from './img/tl.webp';
import img_tg from './img/tg.webp';
import img_tk from './img/tk.webp';
import img_to from './img/to.webp';
import img_tt from './img/tt.webp';
import img_tn from './img/tn.webp';
import img_tr from './img/tr.webp';
import img_tm from './img/tm.webp';
import img_tc from './img/tc.webp';
import img_tv from './img/tv.webp';
import img_ug from './img/ug.webp';
import img_ua from './img/ua.webp';
import img_ae from './img/ae.webp';
import img_gb from './img/gb.webp';
import img_us from './img/us.webp';
import img_um from './img/um.webp';
import img_uy from './img/uy.webp';
import img_uz from './img/uz.webp';
import img_vu from './img/vu.webp';
import img_va from './img/va.webp';
import img_ve from './img/ve.webp';
import img_vn from './img/vn.webp';
import img_vg from './img/vg.webp';
import img_vi from './img/vi.webp';
import img_gb_wls from './img/gb-wls.webp';
import img_wf from './img/wf.webp';
import img_eh from './img/eh.webp';
import img_ye from './img/ye.webp';
import img_zm from './img/zm.webp';
import img_zw from './img/zw.webp';

// https://flagpedia.net/index
// https://en.wikipedia.org/wiki/ISO_3166-1
enum REGO {
  EUROPE = '유럽',
  AFRICA = '아프리카',
  ETC = '기타',
}
const imgArray = [
  { img: img_af, name: '아프가니스탄', reg: '' },
  { img: img_ax, name: '올란드 제도', reg: '' },
  { img: img_al, name: '알바니아', reg: '' },
  { img: img_dz, name: '알제리' }, // 아프리카
  { img: img_as, name: '아메리칸 사모아', reg: '' },
  { img: img_ad, name: '몰도바 공화국', reg: '' },
  { img: img_ao, name: '앙골라', reg: '' },
  { img: img_ai, name: '앵귈라', reg: '' },
  { img: img_aq, name: '남극대륙', reg: '' },
  { img: img_ag, name: '안티구아바부다', reg: '' },
  { img: img_ar, name: '아르헨티나', reg: '' },
  { img: img_am, name: '아르메니아', reg: '' },
  { img: img_aw, name: '아루바', reg: '' },
  { img: img_au, name: '호주', reg: '' },
  { img: img_at, name: '오스트리아', reg: '' },
  { img: img_az, name: '아제르바이잔', reg: '' },
  { img: img_bs, name: '바하마', reg: '' },
  { img: img_bh, name: '카타르', reg: '' },
  { img: img_bd, name: '방글라데시', reg: '' },
  { img: img_bb, name: '바베이도스', reg: '' },
  { img: img_by, name: '벨라루스', reg: '' },
  { img: img_be, name: '벨기에', reg: '' },
  { img: img_bz, name: '벨리즈', reg: '' },
  { img: img_bj, name: '베넹', reg: '' },
  { img: img_bm, name: '버뮤다', reg: '' },
  { img: img_bt, name: '부탄', reg: '' },
  { img: img_bo, name: '볼리비아', reg: '' }, // 남아메리카
  { img: img_ba, name: '보스니아헤르체고비나', reg: '유럽' }, // 유럽
  { img: img_bw, name: '보츠나와', reg: REGO.AFRICA },
  { img: img_bv, name: '노르웨이', reg: REGO.EUROPE },
  { img: img_br, name: '브라질', reg: '' },
  { img: img_io, name: '영국령 인도양 지역', reg: '' }, //(British Indian Ocean Territory)
  { img: img_bn, name: '브루나이', reg: '' },
  { img: img_bg, name: '불가리아', reg: REGO.ETC },
  { img: img_bf, name: '부르키나파소', reg: '' },
  { img: img_bi, name: '부룬디', reg: '' },
  { img: img_kh, name: '캄보디아', reg: '' },
  { img: img_cm, name: '카메룬', reg: '' },
  { img: img_ca, name: '캐나다', reg: '' },
  { img: img_cv, name: '카보베르데', reg: '' },
  { img: img_bq, name: '보네르', reg: '' },
  { img: img_ky, name: '케이맨 제도', reg: '' },
  { img: img_cf, name: '중앙아프리카 공화국', reg: '' },
  { img: img_td, name: '차드', reg: '' },
  { img: img_cl, name: '칠레', reg: '' },
  { img: img_cn, name: '중국', reg: '' },
  { img: img_cx, name: '크리스마스 아일랜드', reg: '' },
  { img: img_cc, name: '코코 섬', reg: '' },
  { img: img_co, name: '콜럼비아', reg: '' },
  { img: img_km, name: '코모로', reg: '' },
  { img: img_cg, name: '콩고 공화국', reg: '' },
  { img: img_cd, name: '콩고 민주 공화국', reg: '' },
  { img: img_ck, name: '쿡 아일랜드', reg: '' },
  { img: img_cr, name: '코스타리카', reg: '' },
  { img: img_ci, name: '', reg: '' },
  { img: img_hr, name: '', reg: '' },
  { img: img_cu, name: '', reg: '' },
  { img: img_cw, name: '', reg: '' },
  { img: img_cy, name: '', reg: '' },
  { img: img_cz, name: '', reg: '' },
  { img: img_dk, name: '', reg: '' },
  { img: img_dj, name: '', reg: '' },
  { img: img_dm, name: '', reg: '' },
  { img: img_do, name: '', reg: '' },
  { img: img_ec, name: '', reg: '' },
  { img: img_eg, name: '', reg: '' },
  { img: img_sv, name: '', reg: '' },
  { img: img_gb_eng, name: '', reg: '' },
  { img: img_gq, name: '', reg: '' },
  { img: img_er, name: '', reg: '' },
  { img: img_ee, name: '', reg: '' },
  { img: img_sz, name: '', reg: '' },
  { img: img_et, name: '', reg: '' },
  { img: img_fk, name: '', reg: '' },
  { img: img_fo, name: '', reg: '' },
  { img: img_fj, name: '', reg: '' },
  { img: img_fi, name: '', reg: '' },
  { img: img_fr, name: '', reg: '' },
  { img: img_gf, name: '', reg: '' },
  { img: img_pf, name: '', reg: '' },
  { img: img_tf, name: '', reg: '' },
  { img: img_ga, name: '', reg: '' },
  { img: img_gm, name: '', reg: '' },
  { img: img_ge, name: '', reg: '' },
  { img: img_de, name: '', reg: '' },
  { img: img_gh, name: '', reg: '' },
  { img: img_gi, name: '', reg: '' },
  { img: img_gr, name: '그리스', reg: '' },
  { img: img_gl, name: '그린란드', reg: '' },
  { img: img_gd, name: '', reg: '' },
  { img: img_gp, name: '', reg: '' },
  { img: img_gu, name: '', reg: '' },
  { img: img_gt, name: '', reg: '' },
  { img: img_gg, name: '', reg: '' },
  { img: img_gn, name: '', reg: '' },
  { img: img_gw, name: '', reg: '' },
  { img: img_gy, name: '', reg: '' },
  { img: img_ht, name: '', reg: '' },
  { img: img_hm, name: '', reg: '' },
  { img: img_hn, name: '', reg: '' },
  { img: img_hk, name: '', reg: '' },
  { img: img_hu, name: '', reg: '' },
  { img: img_is, name: '아이슬란드', reg: '' },
  { img: img_in, name: '', reg: '' },
  { img: img_id, name: '', reg: '' },
  { img: img_ir, name: '', reg: '' },
  { img: img_iq, name: '', reg: '' },
  { img: img_ie, name: '', reg: '' },
  { img: img_im, name: '', reg: '' },
  { img: img_il, name: '', reg: '' },
  { img: img_it, name: '', reg: '' },
  { img: img_jm, name: '', reg: '' },
  { img: img_jp, name: '일본', reg: '' },
  { img: img_je, name: '', reg: '' },
  { img: img_jo, name: '', reg: '' },
  { img: img_kz, name: '', reg: '' },
  { img: img_ke, name: '케냐', reg: '' },
  { img: img_ki, name: '', reg: '' },
  { img: img_kp, name: '', reg: '' },
  { img: img_kr, name: '대한민국', reg: '' },
  { img: img_xk, name: '코소보', reg: '' },
  { img: img_kw, name: '', reg: '' },
  { img: img_kg, name: '', reg: '' },
  { img: img_la, name: '', reg: '' },
  { img: img_lv, name: '', reg: '' },
  { img: img_lb, name: '', reg: '' },
  { img: img_ls, name: '', reg: '' },
  { img: img_lr, name: '', reg: '' },
  { img: img_ly, name: '', reg: '' },
  { img: img_li, name: '', reg: '' },
  { img: img_lt, name: '', reg: '' },
  { img: img_lu, name: '', reg: '' },
  { img: img_mo, name: '', reg: '' },
  { img: img_mg, name: '', reg: '' },
  { img: img_mw, name: '', reg: '' },
  { img: img_my, name: '', reg: '' },
  { img: img_mv, name: '', reg: '' },
  { img: img_ml, name: '', reg: '' },
  { img: img_mt, name: '', reg: '' },
  { img: img_mh, name: '', reg: '' },
  { img: img_mq, name: '', reg: '' },
  { img: img_mr, name: '', reg: '' },
  { img: img_mu, name: '', reg: '' },
  { img: img_yt, name: '', reg: '' },
  { img: img_mx, name: '', reg: '' },
  { img: img_fm, name: '', reg: '' },
  { img: img_md, name: '', reg: '' },
  { img: img_mc, name: '', reg: '' },
  { img: img_mn, name: '', reg: '' },
  { img: img_me, name: '', reg: '' },
  { img: img_ms, name: '', reg: '' },
  { img: img_ma, name: '', reg: '' },
  { img: img_mz, name: '', reg: '' },
  { img: img_mm, name: '', reg: '' },
  { img: img_na, name: '', reg: '' },
  { img: img_nr, name: '', reg: '' },
  { img: img_np, name: '', reg: '' },
  { img: img_nl, name: '', reg: '' },
  { img: img_nc, name: '', reg: '' },
  { img: img_nz, name: '', reg: '' },
  { img: img_ni, name: '', reg: '' },
  { img: img_ne, name: '', reg: '' },
  { img: img_ng, name: '', reg: '' },
  { img: img_nu, name: '', reg: '' },
  { img: img_nf, name: '', reg: '' },
  { img: img_mk, name: '', reg: '' },
  { img: img_gb_nir, name: '', reg: '' },
  { img: img_mp, name: '', reg: '' },
  { img: img_no, name: '', reg: '' },
  { img: img_om, name: '', reg: '' },
  { img: img_pk, name: '', reg: '' },
  { img: img_pw, name: '', reg: '' },
  { img: img_ps, name: '', reg: '' },
  { img: img_pa, name: '', reg: '' },
  { img: img_pg, name: '', reg: '' },
  { img: img_py, name: '', reg: '' },
  { img: img_pe, name: '', reg: '' },
  { img: img_ph, name: '', reg: '' },
  { img: img_pn, name: '', reg: '' },
  { img: img_pl, name: '', reg: '' },
  { img: img_pt, name: '', reg: '' },
  { img: img_pr, name: '', reg: '' },
  { img: img_qa, name: '', reg: '' },
  { img: img_re, name: '', reg: '' },
  { img: img_ro, name: '', reg: '' },
  { img: img_ru, name: '', reg: '' },
  { img: img_rw, name: '', reg: '' },
  { img: img_bl, name: '', reg: '' },
  { img: img_sh, name: '', reg: '' },
  { img: img_kn, name: '', reg: '' },
  { img: img_lc, name: '', reg: '' },
  { img: img_mf, name: '', reg: '' },
  { img: img_pm, name: '', reg: '' },
  { img: img_vc, name: '', reg: '' },
  { img: img_ws, name: '', reg: '' },
  { img: img_sm, name: '', reg: '' },
  { img: img_st, name: '', reg: '' },
  { img: img_sa, name: '', reg: '' },
  { img: img_gb_sct, name: '', reg: '' },
  { img: img_sn, name: '', reg: '' },
  { img: img_rs, name: '', reg: '' },
  { img: img_sc, name: '', reg: '' },
  { img: img_sl, name: '', reg: '' },
  { img: img_sg, name: '', reg: '' },
  { img: img_sx, name: '', reg: '' },
  { img: img_sk, name: '', reg: '' },
  { img: img_si, name: '', reg: '' },
  { img: img_sb, name: '', reg: '' },
  { img: img_so, name: '', reg: '' },
  { img: img_za, name: '', reg: '' },
  { img: img_gs, name: '', reg: '' },
  { img: img_ss, name: '', reg: '' },
  { img: img_es, name: '', reg: '' },
  { img: img_lk, name: '', reg: '' },
  { img: img_sd, name: '', reg: '' },
  { img: img_sr, name: '', reg: '' },
  { img: img_sj, name: '', reg: '' },
  { img: img_se, name: '', reg: '' },
  { img: img_ch, name: '', reg: '' },
  { img: img_sy, name: '', reg: '' },
  { img: img_tw, name: '', reg: '' },
  { img: img_tj, name: '', reg: '' },
  { img: img_tz, name: '', reg: '' },
  { img: img_th, name: '', reg: '' },
  { img: img_tl, name: '', reg: '' },
  { img: img_tg, name: '', reg: '' },
  { img: img_tk, name: '', reg: '' },
  { img: img_to, name: '', reg: '' },
  { img: img_tt, name: '', reg: '' },
  { img: img_tn, name: '', reg: '' },
  { img: img_tr, name: '', reg: '' },
  { img: img_tm, name: '', reg: '' },
  { img: img_tc, name: '', reg: '' },
  { img: img_tv, name: '', reg: '' },
  { img: img_ug, name: '', reg: '' },
  { img: img_ua, name: '', reg: '' },
  { img: img_ae, name: '', reg: '' },
  { img: img_gb, name: '', reg: '' },
  { img: img_us, name: '', reg: '' },
  //   { img: img_um,  name: '' , reg : '' },
  { img: img_uy, name: '', reg: '' },
  { img: img_uz, name: '', reg: '' },
  { img: img_vu, name: '', reg: '' },
  { img: img_va, name: '', reg: '' },
  { img: img_ve, name: '', reg: '' },
  { img: img_vn, name: '', reg: '' },
  { img: img_vg, name: '', reg: '' },
  { img: img_vi, name: '', reg: '' },
  { img: img_gb_wls, name: '', reg: '' },
  { img: img_wf, name: '', reg: '' },
  { img: img_eh, name: '', reg: '' },
  { img: img_ye, name: '', reg: '' },
  { img: img_zm, name: '', reg: '' },
  { img: img_zw, name: '', reg: '' },
];

const flagUrl = [
  'af.webp',
  'ax.webp',
  'al.webp',
  'dz.webp',
  'as.webp',
  'ad.webp',
  'ao.webp',
  'ai.webp',
  'aq.webp',
  'ag.webp',
  'ar.webp',
  'am.webp',
  'aw.webp',
  'au.webp',
  'at.webp',
  'az.webp',
  'bs.webp',
  'bh.webp',
  'bd.webp',
  'bb.webp',
  'by.webp',
  'be.webp',
  'bz.webp',
  'bj.webp',
  'bm.webp',
  'bt.webp',
  'bo.webp',
  'ba.webp',
  'bw.webp',
  'bv.webp',
  'br.webp',
  'io.webp',
  'bn.webp',
  'bg.webp',
  'bf.webp',
  'bi.webp',
  'kh.webp',
  'cm.webp',
  'ca.webp',
  'cv.webp',
  'bq.webp',
  'ky.webp',
  'cf.webp',
  'td.webp',
  'cl.webp',
  'cn.webp',
  'cx.webp',
  'cc.webp',
  'co.webp',
  'km.webp',
  'cg.webp',
  'cd.webp',
  'ck.webp',
  'cr.webp',
  'ci.webp',
  'hr.webp',
  'cu.webp',
  'cw.webp',
  'cy.webp',
  'cz.webp',
  'dk.webp',
  'dj.webp',
  'dm.webp',
  'do.webp',
  'ec.webp',
  'eg.webp',
  'sv.webp',
  'gb-eng.webp',
  'gq.webp',
  'er.webp',
  'ee.webp',
  'sz.webp',
  'et.webp',
  'fk.webp',
  'fo.webp',
  'fj.webp',
  'fi.webp',
  'fr.webp',
  'gf.webp',
  'pf.webp',
  'tf.webp',
  'ga.webp',
  'gm.webp',
  'ge.webp',
  'de.webp',
  'gh.webp',
  'gi.webp',
  'gr.webp',
  'gl.webp',
  'gd.webp',
  'gp.webp',
  'gu.webp',
  'gt.webp',
  'gg.webp',
  'gn.webp',
  'gw.webp',
  'gy.webp',
  'ht.webp',
  'hm.webp',
  'hn.webp',
  'hk.webp',
  'hu.webp',
  'is.webp',
  'in.webp',
  'id.webp',
  'ir.webp',
  'iq.webp',
  'ie.webp',
  'im.webp',
  'il.webp',
  'it.webp',
  'jm.webp',
  'jp.webp',
  'je.webp',
  'jo.webp',
  'kz.webp',
  'ke.webp',
  'ki.webp',
  'kp.webp',
  'kr.webp',
  'xk.webp',
  'kw.webp',
  'kg.webp',
  'la.webp',
  'lv.webp',
  'lb.webp',
  'ls.webp',
  'lr.webp',
  'ly.webp',
  'li.webp',
  'lt.webp',
  'lu.webp',
  'mo.webp',
  'mg.webp',
  'mw.webp',
  'my.webp',
  'mv.webp',
  'ml.webp',
  'mt.webp',
  'mh.webp',
  'mq.webp',
  'mr.webp',
  'mu.webp',
  'yt.webp',
  'mx.webp',
  'fm.webp',
  'md.webp',
  'mc.webp',
  'mn.webp',
  'me.webp',
  'ms.webp',
  'ma.webp',
  'mz.webp',
  'mm.webp',
  'na.webp',
  'nr.webp',
  'np.webp',
  'nl.webp',
  'nc.webp',
  'nz.webp',
  'ni.webp',
  'ne.webp',
  'ng.webp',
  'nu.webp',
  'nf.webp',
  'mk.webp',
  'gb-nir.webp',
  'mp.webp',
  'no.webp',
  'om.webp',
  'pk.webp',
  'pw.webp',
  'ps.webp',
  'pa.webp',
  'pg.webp',
  'py.webp',
  'pe.webp',
  'ph.webp',
  'pn.webp',
  'pl.webp',
  'pt.webp',
  'pr.webp',
  'qa.webp',
  're.webp',
  'ro.webp',
  'ru.webp',
  'rw.webp',
  'bl.webp',
  'sh.webp',
  'kn.webp',
  'lc.webp',
  'mf.webp',
  'pm.webp',
  'vc.webp',
  'ws.webp',
  'sm.webp',
  'st.webp',
  'sa.webp',
  'gb-sct.webp',
  'sn.webp',
  'rs.webp',
  'sc.webp',
  'sl.webp',
  'sg.webp',
  'sx.webp',
  'sk.webp',
  'si.webp',
  'sb.webp',
  'so.webp',
  'za.webp',
  'gs.webp',
  'ss.webp',
  'es.webp',
  'lk.webp',
  'sd.webp',
  'sr.webp',
  'sj.webp',
  'se.webp',
  'ch.webp',
  'sy.webp',
  'tw.webp',
  'tj.webp',
  'tz.webp',
  'th.webp',
  'tl.webp',
  'tg.webp',
  'tk.webp',
  'to.webp',
  'tt.webp',
  'tn.webp',
  'tr.webp',
  'tm.webp',
  'tc.webp',
  'tv.webp',
  'ug.webp',
  'ua.webp',
  'ae.webp',
  'gb.webp',
  'us.webp',
  'um.webp',
  'uy.webp',
  'uz.webp',
  'vu.webp',
  'va.webp',
  've.webp',
  'vn.webp',
  'vg.webp',
  'vi.webp',
  'gb-wls.webp',
  'wf.webp',
  'eh.webp',
  'ye.webp',
  'zm.webp',
  'zw.webp',
];

console.log(
  flagUrl
    .map((f) => {
      return `import img_${f.replace('-', '_').replace('.webp', '')} from './img/${f}';`;
    })
    .join(' ')
);

console.log(
  flagUrl
    .map((f) => {
      return ` { img :  img_${f.replace('-', '_').replace('.webp', '')} , name : '' },`;
    })
    .join(' ')
);

export default function Flags() {
  return (
    <ul className={classNames(styles.flag_ul)}>
      {imgArray.map((url) => (
        <li key={url.img} className={classNames(styles.flag_li)}>
          <dl className={classNames(styles.flag_dl)}>
            <dt>
              <img height={100} src={url.img} alt="" />
            </dt>
            <dd style={{ textAlign: 'center', fontSize: 17, marginTop: 4, fontWeight: 'bold' }}>&nbsp;{url.name}&nbsp;</dd>
          </dl>
        </li>
      ))}
    </ul>
  );
}
