/* eslint-disable no-unreachable */
import Flags from './flags/Flags';

export default function Home() {
  return (
    <>
      <header style={{ overflow: 'auto' }}>
        <h1>flags</h1>
        <h2>깃발 리스트</h2>
      </header>
      <Flags></Flags>
    </>
  );
}
